// The main container element
.Collapsible {
    background-color: $base;
  }
  
  
  //The content within the collaspable area
  .Collapsible__contentInner {
    padding: 10px;
    border: 1px solid $lightGrey;
    border-top: 0;
  
    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
  
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  //The link which when clicked opens the collapsable area
  .Collapsible__trigger {
    display: block;
    font-weight: 400;
    text-decoration: none;
    color: $grey;
    position: relative;
    border: 1px solid white;
    padding: 10px;
    background: $mainColor;
    color: white;
  
  
    &:after {
      font-family: 'FontAwesome';
      content: '\f107';
      position: absolute;
      right: 10px;
      top: 10px;
      display: block;
      transition: transform 300ms;
    }
  
    &.is-open {
      &:after {
        transform: rotateZ(180deg);
      }
    }
  
    &.is-disabled {
      opacity: 0.5;
      background-color: grey;
    }
  }
  
  .CustomTriggerCSS {
    background-color: lightcoral;
    transition: background-color 200ms ease;
    
    
  }
  
  .CustomTriggerCSS--open {
    background-color: darkslateblue;
  }
  
  .Collapsible__custom-sibling {
    padding: 5px;
    font-size: 12px;
    background-color: #CBB700;
    color: black;
  }