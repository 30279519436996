span.ondrive-colored-bullet{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: blue;
  }
  span.onbreak-colored-bullet{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: orange;
  }
  span.inlot-colored-bullet{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: purple;
  }
  span.clockedin-colored-bullet{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: green;
  }
  span.clockedout-colored-bullet{
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    color: red;
  }