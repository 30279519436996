body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.customCalendar{
  display: inline-block !important;
}
.employeeDetailForm {
  border-top: 1px solid #cdcdcd;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;
}
.employeeCardLabel.col-sm-4 {
  text-align: left;
}
.employeeCardLabel.col-sm-8 {
  text-align: left;
}
.employeeDetailFormGroup.position-relative.form-group {
  border-bottom: 1px solid #cdcdcd;
  padding: 12px;
}
.generalForm{
  text-align: left;
}
.md-form input[type=search]{
  color: white !important;
}
label.scheduleSetterLabel {
  padding-right: 10px;
}
.customCalendar.react-calendar.react-calendar--selectRange, .react-calendar.customCalendar {
  width: 100%;
}
label.scheduleFormLabel {
  float: left;
}
p.scheduleFormData {
  float: left;
}